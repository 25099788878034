<template>
  <static-fullscreen-card :title="false" @sendHeight="cardHeight = $event + 50">
    <v-fab-transition v-if="$vuetify.breakpoint.smAndDown">
      <v-btn fab small color="primary lighten-3" style="top: 15px" left @click="showCommentsList = true" absolute>
        <v-icon>fas fa-ellipsis-v</v-icon>
      </v-btn>
    </v-fab-transition>
    <portal to="v-add">
      <v-autocomplete
        v-if="$root.profile.role == 1000"
        :items="$root.dirs['user']"
        item-text="text"
        item-value="value"
        outlined
        v-model="user_id"
        label=""
        hide-details
        dense
        class="mx-1"
      />
    </portal>

    <portal to="v-main">
      <v-navigation-drawer v-if="$vuetify.breakpoint.smAndDown" v-model="showCommentsList" absolute temporary>
        <v-app-bar v-if="false" flat dense color="rgba(0,0,0,0)">
          <v-text-field v-if="false" hide-details dense label="Поиск" append-icon="mdi-magnify" color="grey" class="mt-1"></v-text-field>
        </v-app-bar>
        <ChatList
          :items="commentsList"
          @onChange="
            selected = $event;
            showCommentsList = false;
          "
          :height="cardHeight"
        />
      </v-navigation-drawer>
    </portal>
    <div style="height: 100%" ref="chat_block">
      <splitpanes class="default-theme" style="height: 100%" ref="splitpan">
        <pane min-size="20" size="20" v-if="!$vuetify.breakpoint.smAndDown" class="border">
          <v-card-text style="overflow: hidden" class="pa-0" ref="chat_list">
            <ChatList :items="commentsList" @onChange="selected = $event" id="scrolling" :height="cardHeight" />
          </v-card-text>
        </pane>
        <pane ref="chat_view" style="position: relative">
          <comment-chatview
            v-if="id"
            :style="`position: absolute; bottom: 0; top: 0; right: 0; left: 0px`"
            :id="id"
            :targetName="targetName"
            :needUpdate="needUpdateComments"
            :user_id="user_id"
          />
        </pane>
      </splitpanes>
    </div>
  </static-fullscreen-card>
</template>

<script>
import { Splitpanes, Pane } from "splitpanes";
import "splitpanes/dist/splitpanes.css";

export default {
  components: { Splitpanes, Pane, ChatList: () => import("./chat/ChatList") },
  data() {
    return {
      cardHeight: 0,
      selected: null,
      id: 0,
      user_id: this.$root.profile.id || 0,
      targetName: null,
      needUpdateComments: 0,
      commentsList: [],
      idEdit: 0,
      showCommentsList: false,
      title: "",
      loading: false,
      defaults: {
        sort: { key: "id", order: "ASC" },
      },
      viewWidth: 0,
    };
  },
  created() {
    this.$root.title = "Комментарии";
    this.fitchData();
    console.log(this.$root.dirs["user"]);
  },
  computed: {
    target_id() {
      //{{ $refs.chat_view.offsetWidth }}

      if (!this.selected) return 0;
      return this.commentsList?.[this.selected]?.target_id || 0;
    },
    target_name() {
      if (!this.selected) return null;
      return this.commentsList?.[this.selected]?.target_name || "";
    },
  },
  watch: {
    selected(v) {
      this.id = 0;
      this.$nextTick(() => {
        this.selectGroup(v);
      });
    },
    user_id(v) {
      if (v) this.fitchData();
    },
    showEditDialog() {
      if (!this.showEditDialog) {
        this.$refs.table.updateData();
      }
    },
  },
  methods: {
    paneResize(e) {
      let w = this.$refs.chat_block.offsetWidth;
      this.viewWidth = (w * e[0].size) / 100 + 30;
    },
    selectGroup(m) {
      this.paneResize(this.$refs.splitpan.panes);
      this.id = this.commentsList?.[m]?.target_id || 0;
      this.targetName = this.commentsList?.[m]?.target_name || "";
    },
    async fitchData() {
      this.loading = true;
      let resp;
      this.commentsList = [];
      let user = this.user_id;
      resp = await this.$axios.post("/report_sql", { name: "comments_getChatList", params: { user_id: user } });

      if (resp.data.data.length > 0) {
        this.commentsList = resp.data.data;
      } else {
        this.afterFitch();
        return;
      }
      this.afterFitch();
      return;
    },
    afterFitch() {
      this.loading = false;
    },
    onClickRow(d) {
      this.showEditDialogFun(d.row.id);
    },
    createNew() {
      this.showEditDialogFun(0);
    },
    showEditDialogFun(id) {
      this.idEdit = id;
      this.showEditDialog = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.splitpanes__pane {
  background-color: inherit !important;
}

.border {
  border-right: 1px solid grey;
}
.chat-window ::v-deep .v-toolbar__content {
  padding: 0 !important;
}
.chat-window {
  position: absolute;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
}
.chat-window__container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
}
.chat-window__messages {
  flex-grow: 1;
  overflow: auto;
  min-height: 0;
}

// .v-list-item:after {
//   content: unset;
// }
</style>
